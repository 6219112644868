import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ViewCustomersComponent.css';
import { CustomerDisplay } from '../widgets/DisplayCustomersComponent';
import DisplayCustomersComponent from '../widgets/DisplayCustomersComponent';
import SessionReadout from '../widgets/SessionReadoutComponent';
import LeftNav from '../widgets/LeftNavComponent';
import ModePicker from '../widgets/ModePicker';
import DatePicker from '../widgets/DatePicker';
import Checkbox from '../widgets/CheckboxComponent';
import SearchField from '../widgets/SearchFieldComponent';
import { dateFromEhsStringToMs } from '../tools/DateFunctions';

const ViewCustomersComponent: React.FC = () => {
  const [session, setSession] = useState<{ username: string; role: string }>({ username: '', role: '' });
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [displayedCustomers, setDisplayedCustomers] = useState<CustomerDisplay[]>([]);

  const NOT_USING = 'Not Using';
  const ON_DATE = 'On Date';
  const NEAR_DATE = 'Near Date';
  const AFTER_DATE = 'After Date';
  const DATE_RANGE = 'Between Dates';

  const MALE = "Male";
  const FEMALE = "Female";

  const dateTypeModes = [NOT_USING, ON_DATE, NEAR_DATE, AFTER_DATE, DATE_RANGE];
  const [currentDateTypeMode, setCurrentDateTypeMode] = useState<string>(dateTypeModes[0]);
  const [createdDate1, setCreatedDate1] = useState<number>(new Date().getTime());
  const [createdDate2, setCreatedDate2] = useState<number>(new Date().getTime());
  const [birthDate, setBirthDate] = useState<number>(new Date().getTime());

  const genderTypeModes = [NOT_USING, FEMALE, MALE];
  const [currentGenderTypeMode, setCurrentGenderTypeMode] = useState<string>(dateTypeModes[0]);

  const [checkingFirstName, setCheckingFirstName] = useState<boolean>(false);
  const [checkingMiddleName, setCheckingMiddleName] = useState<boolean>(false);
  const [checkingLastName, setCheckingLastName] = useState<boolean>(false);
  const [checkingBirthDate, setCheckingBirthDate] = useState<boolean>(false);
  const [checkingEmail, setCheckingEmail] = useState<boolean>(false);
  const [checkingPhone, setCheckingPhone] = useState<boolean>(false);
  const [checkingAddress1, setCheckingAddress1] = useState<boolean>(false);
  const [checkingCity, setCheckingCity] = useState<boolean>(false);
  const [checkingState, setCheckingState] = useState<boolean>(false);
  const [checkingZip, setCheckingZip] = useState<boolean>(false);
  
  const [firstNameSearchString, setFirstNameSearchString] = useState<string>('');
  const [middleNameSearchString, setMiddleNameSearchString] = useState<string>('');
  const [lastNameSearchString, setLastNameSearchString] = useState<string>('');
  const [emailSearchString, setEmailSearchString] = useState<string>('');
  const [phoneSearchString, setPhoneSearchString] = useState<string>('');
  const [address1SearchString, setAddress1SearchString] = useState<string>('');
  const [citySearchString, setCitySearchString] = useState<string>('');
  const [stateSearchString, setStateSearchString] = useState<string>('');
  const [zipSearchString, setZipSearchString] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch('/api/session');
        const sessionData = await response.json();

        if (sessionData.loggedIn) {
          setIsAuthorized(true);
          setSession(sessionData);
        } else {
          navigate('/fulfillmentportal');
        }
      } catch (error) {
        console.error('Error checking session:', error);
        navigate('/fulfillmentportal');
      }
    };

    checkSession();
  }, [navigate]);

  const handleDateTypeModeChange = (mode: string) => {
    setCurrentDateTypeMode(mode);
  };
  const handleGenderModeChange = (mode: string) => {
    setCurrentGenderTypeMode(mode);
  }

  const handleDate1Change = (date: string) => {
    setCreatedDate1(dateFromEhsStringToMs(date));
  };
  const handleDate2Change = (date: string) => {
    setCreatedDate2(dateFromEhsStringToMs(date));
  };
  const handleBirthDateChange = (date: string) => {
    setBirthDate(dateFromEhsStringToMs(date));
  };

  const handleFirstNameCheck = (isChecked: boolean) => {
    setCheckingFirstName(isChecked);
    //console.log(' -- handleFirstNameCheck  ---isChecked was: ',isChecked);
  };
  const handleMiddleNameCheck = (isChecked: boolean) => {
    setCheckingMiddleName(isChecked);
    //console.log(' -- handleMiddleNameCheck  ---isChecked was: ',isChecked);
  };
  const handleLastNameCheck = (isChecked: boolean) => {
    setCheckingLastName(isChecked);
    //console.log(' -- handleLastNameCheck  ---isChecked was: ',isChecked);
  };
  const handleBirthDateCheck = (isChecked: boolean) => {
    setCheckingBirthDate(isChecked);
  };
  const handleEmailCheck = (isChecked: boolean) => {
    setCheckingEmail(isChecked);
  };
  const handlePhoneCheck = (isChecked: boolean) => {
    setCheckingPhone(isChecked);
  };
  const handleAddress1Check = (isChecked: boolean) => {
    setCheckingAddress1(isChecked);
  };
  const handleCityCheck = (isChecked: boolean) => {
    setCheckingCity(isChecked);
  };
  const handleStateCheck = (isChecked: boolean) => {
    setCheckingState(isChecked);
  };
  const handleZipCheck = (isChecked: boolean) => {
    setCheckingZip(isChecked);
  };

  const handleFirstNameSearchText = (searchString: string) => {
    //console.log(' --- setting firstNameSearchString to: ',searchString);
    setFirstNameSearchString(searchString);
  };
  const handleMiddleNameSearchText = (searchString: string) => {
    //console.log(' --- setting middleNameSearchString to: ',searchString);
    setMiddleNameSearchString(searchString);
  };
  const handleLastNameSearchText = (searchString: string) => {
    //console.log(' --- setting lastNameSearchString to: ',searchString);
    setLastNameSearchString(searchString);
  };
  const handleEmailSearchText = (searchString: string) => {
    setEmailSearchString(searchString);
  };
  const handlePhoneSearchText = (searchString: string) => {
    setPhoneSearchString(searchString);
  };
  const handleAddress1SearchText = (searchString: string) => {
    setAddress1SearchString(searchString);
  };
  const handleCitySearchText = (searchString: string) => {
    setCitySearchString(searchString);
  };
  const handleStateSearchText = (searchString: string) => {
    setStateSearchString(searchString);
  };
  const handleZipSearchText = (searchString: string) => {
    setZipSearchString(searchString);
  };

  const searchCustomers = async () => {
    let url = `/api/oyl-customers-filtered`;
    let first_param_added = false;
    const ms4days = 345600000;
    const ms10days = 864000000;
    let calcDate1, calcDate2;
    switch(currentDateTypeMode) {
      case ON_DATE:
        // only uses selectedDate1
        url += `?date1=${createdDate1}`;
        first_param_added = true;
        break;
      case NEAR_DATE:
        // only uses selectedDate1
        calcDate1 = createdDate1 - ms4days;
        calcDate2 = createdDate1 + ms4days;
        url += `?date1=${calcDate1}&date2=${calcDate2}`;
        first_param_added = true;
        break;
      case AFTER_DATE:
        // only uses date1
        calcDate1 = createdDate1;
        calcDate2 = createdDate1 + ms10days;
        url += `?date1=${calcDate1}&date2=${calcDate2}`;
        first_param_added = true;
        break;
      case DATE_RANGE:
        // uses both date1 and date2
        if (createdDate2 < createdDate1) {
          calcDate1 = createdDate2;
          calcDate2 = createdDate1;
        } else {
          calcDate1 = createdDate1;
          calcDate2 = createdDate2;
        }
        url += `?date1=${calcDate1}&date2=${calcDate2}`;
        first_param_added = true;
        break;

      case NOT_USING:
        break;
    }
    if (checkingBirthDate) {
      url += (first_param_added ? '&' : '?') + `dob=${birthDate}`;
      first_param_added = true;
    }

    if (checkingFirstName && firstNameSearchString) {
      url += (first_param_added ? '&' : '?');
      url += `fname=${firstNameSearchString}`;
      first_param_added = true;
    }

    if (checkingMiddleName && middleNameSearchString) {
      url += (first_param_added ? '&' : '?');
      url += `mname=${middleNameSearchString}`;
      first_param_added = true;
    }
    if (checkingLastName && lastNameSearchString) {
      url += (first_param_added ? '&' : '?');
      url += `lname=${lastNameSearchString}`;
      first_param_added = true;
    }
    if (checkingEmail && emailSearchString) {
      url += (first_param_added ? '&' : '?');
      url += `em=${emailSearchString}`;
      first_param_added = true;
    }
    if (checkingPhone && phoneSearchString) {
      url += (first_param_added ? '&' : '?');
      url += `ph=${phoneSearchString}`;
      first_param_added = true;
    }
    if (currentGenderTypeMode !== NOT_USING) {
      url += (first_param_added ? '&' : '?');
      url += `gen=${currentGenderTypeMode}`;
      first_param_added = true;
    }
    if (checkingAddress1 && address1SearchString) {
      url += (first_param_added ? '&' : '?');
      url += `add1=${address1SearchString}`;
      first_param_added = true;
    }
    if (checkingCity && citySearchString) {
      url += (first_param_added ? '&' : '?');
      url += `city=${citySearchString}`;
      first_param_added = true;
    }
    if (checkingState && stateSearchString) {
      url += (first_param_added ? '&' : '?');
      url += `state=${stateSearchString}`;
      first_param_added = true;
    }
    if (checkingZip && zipSearchString) {
      url += (first_param_added ? '&' : '?');
      url += `zip=${zipSearchString}`;
      first_param_added = true;
    }

    try {
      let response = await fetch(url);
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      let data = await response.json();
      //console.log(' -- -- data from /api/oyl-customers-filtered endpoint: ',data);
      setDisplayedCustomers(data);
    } catch (error) {
      console.error('Error fetching customer details:', error);
      throw error;
    }
  };

  return (
    <div className="fulfillment-dashboard-container">
      <LeftNav currentview={"customers"} />
      <div className="search-customers-cont">
        <div className="inner-cont">
          <SessionReadout className="page-headers" session={session} viewname={"customers - search"} />
          <div className="search-results-label">Search Results:</div>
          <div className="search-settings">
            <div className="search-settings-inner">
              <div className="widgets-row">
                <div className="settings-widget">
                  <div className="search-customers-sw-label date-created">Date Created</div>
                  <ModePicker modes={dateTypeModes} onModeChange={handleDateTypeModeChange}></ModePicker>
                  {currentDateTypeMode !== NOT_USING ? (
                    <form>
                      <DatePicker onDateChange={handleDate1Change} earliestYear={2020}></DatePicker>
                    </form>
                  ) : null}
                  {currentDateTypeMode === DATE_RANGE ? (
                    <form>
                      <DatePicker onDateChange={handleDate2Change} earliestYear={2020}></DatePicker>
                    </form>
                  ) : null}
                </div>

                <div className="settings-widget">
                  <div className="search-customers-sw-label">First Name</div>
                  <SearchField onTextChange={handleFirstNameSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleFirstNameCheck}></Checkbox>
                </div>

                <div className="settings-widget">
                  <div className="search-customers-sw-label">Last Name</div>
                  <SearchField onTextChange={handleLastNameSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleLastNameCheck}></Checkbox>
                </div>

                <div className="settings-widget">
                  <div className="search-customers-sw-label">Middle Name</div>
                  <SearchField onTextChange={handleMiddleNameSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleMiddleNameCheck}></Checkbox>
                </div>

              </div>

              <div className="widgets-row">

                <div className="settings-widget">
                  <div className="search-customers-sw-label">Date of Birth</div>
                    <form>
                      <DatePicker onDateChange={handleBirthDateChange} earliestYear={1900}></DatePicker>
                    </form>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleBirthDateCheck}></Checkbox>
                </div>

                <div className="settings-widget">
                  <div className="search-customers-sw-label">Gender</div>
                    <form>
                    <ModePicker modes={genderTypeModes} onModeChange={handleGenderModeChange}></ModePicker>
                    </form>
                </div>
                

                <div className="settings-widget">
                  <div className="search-customers-sw-label">Phone</div>
                  <SearchField onTextChange={handlePhoneSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handlePhoneCheck}></Checkbox>
                </div>

                <div className="settings-widget">
                  <div className="search-customers-sw-label">Email</div>
                  <SearchField onTextChange={handleEmailSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleEmailCheck}></Checkbox>
                </div>
              </div>

              <div className="widgets-row">
                
                <div className="settings-widget">
                  <div className="search-customers-sw-label">Address line 1</div>
                  <SearchField onTextChange={handleAddress1SearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleAddress1Check}></Checkbox>
                </div>
                
                <div className="settings-widget">
                  <div className="search-customers-sw-label">City</div>
                  <SearchField onTextChange={handleCitySearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleCityCheck}></Checkbox>
                </div>
                
                <div className="settings-widget">
                  <div className="search-customers-sw-label">State</div>
                  <SearchField onTextChange={handleStateSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleStateCheck}></Checkbox>
                </div>
                
                <div className="settings-widget">
                  <div className="search-customers-sw-label">Zip Code</div>
                  <SearchField onTextChange={handleZipSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleZipCheck}></Checkbox>
                </div>

              </div>

            </div>
          </div>
          <div className="search-button" onClick={searchCustomers}>SEARCH</div>
          <DisplayCustomersComponent customers={displayedCustomers} session={session} />
        </div>
      </div>
    </div>
  );
};

export default ViewCustomersComponent;
