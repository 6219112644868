import React, { useState, useEffect } from 'react';

interface SearchFieldProps {
  initialText?: string;
  maxChars?: number;
  onTextChange: (text: string) => void;
}

const SearchField: React.FC<SearchFieldProps> = ({ initialText = '', maxChars = 32, onTextChange }) => {
  const [text, setText] = useState<string>(initialText);

  useEffect(() => {
    onTextChange(text);
  }, [text]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value.slice(0, maxChars); // Limit input to 32 characters
    setText(newText);
  };

  return (
    <div className='search-field-wrapper'>
      <input
        className="search-field-input"
        type="text"
        value={text}
        onChange={handleTextChange}
        maxLength={maxChars}
        placeholder="Type here..."
      />
    </div>
  );
};

export default SearchField;