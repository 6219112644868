
// Change from format '2024-09-19T23:12:02.000Z'
// to format '2024-09-19 7:12pm'
export function dateFromDBStringToPrettySimpleString(
  dateString:string,
  americanStyle:boolean = false,
  singleLine:boolean = false,
  excludeTime:boolean = false)
{
  const date = new Date(dateString);
  // Get components for formatting
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const toTheDay = americanStyle ? `${month}-${day}-${year}` : `${year}-${month}-${day}`;

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  // Convert to 12-hour format
  hours = hours % 12 || 12; // Adjust hours for 12-hour format
  if (excludeTime) return toTheDay;
  if (singleLine) return toTheDay + ` at ${hours}:${minutes}${ampm}`;
  return toTheDay + `\n${hours}:${minutes}${ampm}`;
}

// Takes the EHS-friendly date string used by DatePicker and returns a Date object.
export function dateFromEhsStringToJSObj(dateString:string) {
  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(4, 6), 10) - 1; // Months are 0-based in JavaScript
  const day = parseInt(dateString.substring(6, 8), 10);
  return new Date(year, month, day);
}

// Takes the EHS-friendly date string used by DatePicker and returns a number (ms).
export function dateFromEhsStringToMs(dateString:string) {
  return dateFromEhsStringToJSObj(dateString).getTime();
}

// Takes format '200112010000' and returns format '2001-12-01'
export function fromCustomerDateToDBDate(dateString:string) {
  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(4, 6), 10) - 1; // Months are 0-based in JavaScript
  const day = parseInt(dateString.substring(6, 8), 10);
  return '' + year + '-' + month + '-' + day;
}

export function fromDateDateToDatePickerFormat(dateString:string) {
  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(5, 7), 10);
  const day = parseInt(dateString.substring(8, 10), 10);
  const formattedDate = `${year}${String(month).padStart(2, '0')}${String(day).padStart(2, '0')}0000`;
  return formattedDate;
}