import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardComponent.css';
import './ViewOrdersComponent.css';
import { OrderDisplay } from '../widgets/DisplayOrdersComponent';
import SessionReadout from '../widgets/SessionReadoutComponent';
import LeftNav from '../widgets/LeftNavComponent';
import DisplayOrdersComponent from '../widgets/DisplayOrdersComponent';
import ModePicker from '../widgets/ModePicker';
import DatePicker from '../widgets/DatePicker';
import Checkbox from '../widgets/CheckboxComponent';
import SearchField from '../widgets/SearchFieldComponent';
import { dateFromEhsStringToMs } from '../tools/DateFunctions';

const ViewOrdersComponent: React.FC = () => {
  const [session, setSession] = useState<{ username: string; role: string }>({ username: '', role: '' });
  const [isAuthorized, setIsAuthorized] = useState(false);
  
  const [displayedOrders, setDisplayedOrders] = useState<OrderDisplay[]>([]);

  const NOT_USING = 'Not Using';
  const ON_DATE = 'On Date';
  const NEAR_DATE = 'Near Date';
  const AFTER_DATE = 'After Date';
  const DATE_RANGE = 'Between Dates';
  
  const dateTypeModes = [NOT_USING, ON_DATE, NEAR_DATE, AFTER_DATE, DATE_RANGE];
  const [currentDateTypeMode, setCurrentDateTypeMode] = useState<string>(dateTypeModes[0]);
  const [selectedDate1, setSelectedDate1] = useState<number>(new Date().getTime());
  const [selectedDate2, setSelectedDate2] = useState<number>(new Date().getTime());
  const [checkingWooID, setCheckingWooID] = useState<boolean>(false);
  const [checkingOylID, setCheckingOylID] = useState<boolean>(false);
  const [checkingEhsID, setCheckingEhsID] = useState<boolean>(false);
  const [checkingSku, setCheckingSku] = useState<boolean>(false);
  const [wooIDSearchString, setWooIDSearchString] = useState<string>('');
  const [oylIDSearchString, setOylIDSearchString] = useState<string>('');
  const [ehsIDSearchString, setEhsIDSearchString] = useState<string>('');
  const [skuSearchString, setSkuSearchString] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch('/api/session');
        const sessionData = await response.json();

        if (sessionData.loggedIn) {
          setIsAuthorized(true);
          setSession(sessionData);
          console.log('session.username: ', session.username);
          console.log('session.role: ', session.role);
        } else {
          navigate('/fulfillmentportal'); // Redirect to login if not authorized
        }
      } catch (error) {
        console.error('Error checking session:', error);
        navigate('/fulfillmentportal'); // Redirect to login if there's an error
      }
    };

    checkSession();
  }, [navigate]);

  const handleDateTypeModeChange = (mode:string) => {
    setCurrentDateTypeMode(mode);
  }
  const handleDate1Change = (date: string) => {
    setSelectedDate1(dateFromEhsStringToMs(date));
    //console.log(' --- selectedDate1: ',selectedDate1);
  }
  const handleDate2Change = (date: string) => {
    setSelectedDate2(dateFromEhsStringToMs(date));
    //console.log(' --- selectedDate2: ',selectedDate2);
  }

  const handleWooIDCheck = (isChecked:boolean) => {
    setCheckingWooID(isChecked);
  }
  const handleOylIDCheck = (isChecked:boolean) => {
    setCheckingOylID(isChecked);
  }
  const handleEhsIDCheck = (isChecked:boolean) => {
    setCheckingEhsID(isChecked);
  }
  const handleSkuCheck = (isChecked:boolean) => {
    setCheckingSku(isChecked);
  }

  const handleWooIDSearchText = (searchString:string) => {
    setWooIDSearchString(searchString);
  }
  const handleOylIDSearchText = (searchString:string) => {
    setOylIDSearchString(searchString);
  }
  const handleEhsIDSearchText = (searchString:string) => {
    setEhsIDSearchString(searchString);
  }
  const handleSkuSearchText = (searchString:string) => {
    setSkuSearchString(searchString);
  }
  
  const searchOrders = async () => {
    let url = `/api/oyl-orders-filtered`;
    let first_param_added = false;
    const ms4days = 345600000;
    const ms10days = 864000000;
    let calcDate1, calcDate2;
    //console.log(' --- currentDateTypeMode: ',currentDateTypeMode);
    switch(currentDateTypeMode) {
      case ON_DATE:
        // only uses selectedDate1
        url += `?date1=${selectedDate1}`;
        first_param_added = true;
        break;
      case NEAR_DATE:
        // only uses selectedDate1
        calcDate1 = selectedDate1 - ms4days;
        calcDate2 = selectedDate1 + ms4days;
        url += `?date1=${calcDate1}&date2=${calcDate2}`;
        first_param_added = true;
        break;
      case AFTER_DATE:
        // only uses date1
        calcDate1 = selectedDate1;
        calcDate2 = selectedDate1 + ms10days;
        url += `?date1=${calcDate1}&date2=${calcDate2}`;
        first_param_added = true;
        break;
      case DATE_RANGE:
        // uses both date1 and date2
        if (selectedDate2 < selectedDate1) {
          calcDate1 = selectedDate2;
          calcDate2 = selectedDate1;
        } else {
          calcDate1 = selectedDate1;
          calcDate2 = selectedDate2;
        }
        url += `?date1=${calcDate1}&date2=${calcDate2}`;
        first_param_added = true;
        break;

      case NOT_USING:
        break;
    }

    if (checkingWooID && wooIDSearchString && wooIDSearchString.length) {
      url += (first_param_added ? '&' : '?');
      url += `wcid=${wooIDSearchString}`;
      first_param_added = true;
    }
    if (checkingEhsID && ehsIDSearchString && ehsIDSearchString.length) {
      url += (first_param_added ? '&' : '?');
      url += `ehsid=${ehsIDSearchString}`;
      first_param_added = true;
    }
    if (checkingOylID && oylIDSearchString && oylIDSearchString.length) {
      url += (first_param_added ? '&' : '?');
      url += `oylid=${oylIDSearchString}`;
      first_param_added = true;
    }
    if (checkingSku && skuSearchString && skuSearchString.length) {
      url += (first_param_added ? '&' : '?');
      url += `sku=${skuSearchString}`;
      first_param_added = true;
    }

    if (!first_param_added) {
      console.log('No search parameters entered');
      //yoda TODO: Make a visible screen message to this effect
    } else try {
      //console.log(' --- url to fetch: ',url);
      let response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      let data = await response.json();
      // console.log('Response for request of all orders:');
      // console.log(data);
      setDisplayedOrders(data);
    } catch (error) {
      console.error('Error fetching order details:', error);
      throw error;
    }
  };

  return(
    <div className="fulfillment-dashboard-container">
      <LeftNav currentview="orders"/>
      <div className="search-orders-cont">
        <div className="inner-cont">
          <SessionReadout className="page-headers" session={session} viewname="orders - search"/>
          <div className="search-results-label">Search Results:</div>
          <div className="search-settings">
            <div className="search-settings-inner">
              <div className="widgets-row">

                <div className="settings-widget">
                  <div className="search-orders-sw-label date-created">Date Created</div>
                  <ModePicker modes={dateTypeModes} onModeChange={handleDateTypeModeChange}></ModePicker>
                  {currentDateTypeMode !== NOT_USING ? (
                    <form>
                      <DatePicker onDateChange={handleDate1Change} earliestYear={2020}></DatePicker>
                    </form>
                  ) : null}
                  {currentDateTypeMode === DATE_RANGE ? (
                    <form>
                      <DatePicker onDateChange={handleDate2Change} earliestYear={2020}></DatePicker>
                    </form>
                  ) : null}
                </div>

                <div className="settings-widget">
                  <div className="search-orders-sw-label">Woocommerce ID</div>
                  <SearchField onTextChange={handleWooIDSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleWooIDCheck}></Checkbox>
                </div>

                <div className="settings-widget">
                  <div className="search-orders-sw-label">OYL ID</div>
                  <SearchField onTextChange={handleOylIDSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleOylIDCheck}></Checkbox>
                </div>

                <div className="settings-widget">
                  <div className="search-orders-sw-label">EHS ID</div>
                  <SearchField onTextChange={handleEhsIDSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleEhsIDCheck}></Checkbox>
                </div>

              </div>

              <div className="widgets-row">

                <div className="settings-widget">
                  <div className="search-orders-sw-label">SKU</div>
                  <SearchField onTextChange={handleSkuSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleSkuCheck}></Checkbox>
                </div>

              </div>

            </div>
          </div>
          <div className="search-button" onClick={searchOrders}>SEARCH</div>
          <DisplayOrdersComponent orders={displayedOrders} session={session}/>
        </div>
      </div>
    </div>
  );
};

export default ViewOrdersComponent;