import React from 'react';
import './SingleOrderComponent.css';
import * as ODF from '../tools/OrderDisplayFormatting';
const SingleOrderView: React.FC<any> = ({ order, session, onClose }) => {
  if (!order) return null;
  console.log(' -- order: ', order); // maybe comment out??

  function customerTitle(order:any) {
    return ODF.orderHasBilling(order) ? "Participant" : "Customer";
  }

  function roomForBillingClass(order:any) {
    if (ODF.orderHasBilling(order)) {
      return 'room-for-billing';
    }
    return '';
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content-container">
        <div className="modal-content">
          <div className="single-order-cont">
            <div className="single-order-inner-cont">
              <div className="order-header-info left-half">Woocommerce Order ID: { order.oyl_woocommerce_id }</div>
              <div className="order-header-info right-half">Order Placed: { ODF.getCreatedDateFromOrder(order) }</div>
              <div className="order-header-info">{ customerTitle(order) }: { ODF.getCustomerNameFromOrder(order) } - OYL ID: {
                ODF.getCustomerIdFromOrder(order)}</div>
              {ODF.orderHasBilling(order) ? (
                <div className="order-header-info">Billed To: { ODF.getBillingNameFromOrder(order) } - OYL ID: {
                  ODF.getBillingIdFromOrder(order)} </div>
              ) : null}
              <div className={`scrolling-info-container ${roomForBillingClass(order)}`}>
                <div className="info-section order-info">
                  <div className="info-section-header">Order Info:</div>
                  <div className="subsection all-order-ids">
                    <div className="inline-bullet">Woocommerce ID: {order.oyl_woocommerce_id}</div>
                    <div className="inline-bullet">In-house OYL ID: {order.oyl_id}</div>
                    <div className="inline-bullet">Everly (EHS) ID: {order.id}</div>
                  </div>
                  <div className="subsection order-created">Created: { ODF.getCreatedDateFromOrder(order) }</div>
                  <div className="subsection order-status">EHS Status: {order.ehs_status}</div>
                  <div className="subsection order-tests">Tests: { ODF.getOYLTestIDsFromOrder(order).join(', ') }</div>
                  <div className="subsection order-ehs-tests">EHS Test Names: { ODF.getEHSTestsFromOrder(order).join(', ') }</div>
                  <div className="subsection order-ehs-skus">OYL SKUs: { ODF.getOYLSkusFromOrder(order).join(', ') }</div>
                  <div className="subsection order-note">Customer Note: { ODF.getCustomerNoteFromOrder(order) }</div>
                </div>
                <div className="info-section customer-info">
                  <div className="info-section-header">{ customerTitle(order) } Info:</div>
                  <div className="subsection customer-name">
                    <div className="name-field inline-bullet first-name">First Name: { ODF.getCustomerFirstNameFromOrder(order) }</div>
                    <div className="name-field inline-bullet first-name">Middle: { ODF.getCustomerMiddleNameFromOrder(order) }</div>
                    <div className="name-field inline-bullet last-name">Last Name: { ODF.getCustomerLastNameFromOrder(order) }</div>
                  </div>
                  <div className="subsection customer-oyl-id">Customer OYL ID: { ODF.getCustomerIdFromOrder(order) }</div>
                  <div className="subsection address1">Address line 1: { ODF.getCustomerAddress1FromOrder(order) }</div>
                  <div className="subsection address2">Address line 2: { ODF.getCustomerAddress2FromOrder(order) }</div>
                  <div className="subsection address-city">City: { ODF.getCustomerCityFromOrder(order) }</div>
                  <div className="subsection address-state">State: { ODF.getCustomerStateFromOrder(order) }</div>
                  <div className="subsection zipcode">Zipcode: { ODF.getCustomerZipFromOrder(order) }</div>
                  <div className="subsection birthdate">Date of Birth: { ODF.getCustomerBirthDateFromOrder(order) }</div>
                  <div className="subsection email">Email: { ODF.getCustomerEmailFromOrder(order) }</div>
                  <div className="subsection phone">Phone Number: { ODF.getCustomerPhoneFromOrder(order) }</div>
                </div>
                {ODF.orderHasBilling(order) ? (
                  <div className="info-section billing-info">
                    <div className="info-section-header">Billing Info:</div>
                    <div className="subsection customer-info">
                      <div className="name-field inline-bullet first-name">First Name: { ODF.getBillingFirstNameFromOrder(order) }</div>
                      <div className="name-field inline-bullet first-name">Middle: { ODF.getBillingMiddleNameFromOrder(order) }</div>
                      <div className="name-field inline-bullet last-name">Last Name: { ODF.getBillingLastNameFromOrder(order) }</div>
                    </div>
                    <div className="subsection customer-oyl-id">Billing OYL ID: { ODF.getBillingIdFromOrder(order) }</div>
                    <div className="subsection address1">Address line 1: { ODF.getBillingAddress2FromOrder(order) }</div>
                    <div className="subsection address2">Address line 2: { ODF.getCustomerAddress2FromOrder(order) }</div>
                    <div className="subsection address-city">City: { ODF.getBillingCityFromOrder(order) }</div>
                    <div className="subsection address-state">State: { ODF.getBillingStateFromOrder(order) }</div>
                    <div className="subsection zipcode">Zipcode: { ODF.getBillingZipFromOrder(order) }</div>
                    <div className="subsection birthdate">Date of Birth: { ODF.getBillingBirthDateFromOrder(order) }</div>
                    <div className="subsection email">Email: { ODF.getBillingEmailFromOrder(order) }</div>
                    <div className="subsection phone">Phone Number: { ODF.getBillingPhoneFromOrder(order) }</div>
                  </div>
                ) : null}
                  <div className="info-section last-ehs-response-info">
                    <div className="info-section-header">Last EHS Response:</div>
                    <div className="last-ehs-response"> { ODF.getLastEhsResponseFromOrder(order) } </div>
                  </div>
              </div>
            </div>
          </div>
          <div onClick={onClose} className="close-modal">X</div>
        </div>
      </div>
    </div>
  );
};

export default SingleOrderView;