import { dateFromDBStringToPrettySimpleString } from './DateFunctions';
  
// Get a viewer-friendly formatting of the created date of the given order
export function getCreatedDateFromOrder(order:any) {
  if (order && order.created) {
    return dateFromDBStringToPrettySimpleString(order.created);
  }
  return '???';
}
// Get the full name of the customer associated with the given order
export function getCustomerNameFromOrder(order:any) {
  if (!order || !order.customer) return '???';
  let retval = order.customer.last_name + ', ' + order.customer.first_name;
  if (order.customer.middle_name && order.customer.middle_name.length > 0) {
    retval += ' ' + order.customer.middle_name;
  }
  return retval;
}

export function getCustomerFirstNameFromOrder(order:any) {
  if (!order || !order.customer || !order.customer.first_name) return '???';
  return order.customer.first_name;
}
export function getCustomerLastNameFromOrder(order:any) {
  if (!order || !order.customer || !order.customer.last_name) return '???';
  return order.customer.last_name;
}
export function getCustomerMiddleNameFromOrder(order:any) {
  if (!order || !order.customer || !order.customer.middle_name) return '';
  return order.customer.middle_name;
}

// Get the full name of the billing associated with the given order
export function getBillingNameFromOrder(order:any) {
  if (!order || !order.billing || !order.billing.first_name) return '???';
  let retval = order.billing.last_name + ', ' + order.billing.first_name;
  if (order.billing.middle_name && order.billing.middle_name.length > 0) {
    retval += ' ' + order.billing.middle_name;
  }
  return retval;
}

export function orderHasBilling(order:any) {
  return (order && order.billing && order.billing.oyl_id);
}


// Get a viewer-friendly string representing the billing ID (if any) for the order
export function getOYLBillingIDFromOrder(order:any) {
  if (order && order.oyl_id_billing) {
    return (order.oyl_id_billing < 0) ? "n/a" : "ID: " + order.oyl_id_billing;
  }
  return "?";
}

// Get the Array of coupon ids for the given order, if any
export function getOYLCouponsFromOrder(order:any) {
  if (order && order.oyl_coupons) {
    return order.oyl_coupons;
  } else if (order && order.coupons) {
    return order.coupons;
  }
  return [];
}

// Get the Array of test ids associated with the given order
export function getOYLTestIDsFromOrder(order:any) {
  if (order && order.oyl_id_tests) {
    return order.oyl_id_tests;
  }
  return [];
}

// Get the Array of EHS test descriptions associated with the given order
export function getEHSTestsFromOrder(order:any) {
  if (order && order.tests) {
    return order.tests;
  }
  return [];
}

// Get the Array of skus associated with the given order
export function getOYLSkusFromOrder(order:any) {
  if (order && order.oyl_skus) {
    return order.oyl_skus;
  }
  return [];
}

// Get the customer note associated with the given order if any, otherwise return "n/a"
export function getCustomerNoteFromOrder(order:any) {
  if (order && order.customer_note) {
    return order.customer_note;
  }
  return "n/a";
}

export function getCustomerIdFromOrder(order:any) {
    if (order && order.customer && order.customer.oyl_id) {
      return order.customer.oyl_id;
    } else if (order && order.oyl_id_customer) {
      return order.oyl_id_customer;
    }
    return 'unknown; error';
}

export function getCustomerAddress1FromOrder(order:any) {
  if (order && order.customer && order.customer.address_line) {
    return order.customer.address_line;
  }
  return 'unknown; error';
}

export function getCustomerAddress2FromOrder(order:any) {
  if (order && order.customer && order.customer.address_line2) {
    return order.customer.address_line2;
  }
  return '';
}

export function getCustomerCityFromOrder(order:any) {
  if (order && order.customer && order.customer.address_city) {
    return order.customer.address_city;
  }
  return 'unknown; error';
}

export function getCustomerStateFromOrder(order:any) {
  if (order && order.customer && order.customer.address_state) {
    return order.customer.address_state;
  }
  return 'unknown; error';
}

export function getCustomerZipFromOrder(order:any) {
  if (order && order.customer && order.customer.address_zip) {
    return order.customer.address_zip;
  }
  return 'unknown; error';
}

export function getCustomerBirthDateFromOrder(order:any) {
  if (order && order.customer && order.customer.birth_date) {
    return dateFromDBStringToPrettySimpleString(order.customer.birth_date, true, true, true);
  }
  return 'unknown; error';
}

export function getCustomerEmailFromOrder(order:any) {
  if (order && order.customer && order.customer.email) {
    return order.customer.email;
  }
  return 'not available';
}

export function getCustomerPhoneFromOrder(order:any) {
  if (order && order.customer && order.customer.phone) {
    return order.customer.phone;
  }
  return 'not available';
}


export function getBillingFirstNameFromOrder(order:any) {
  if (!order || !order.billing || !order.billing.first_name) return '???';
  return order.billing.first_name;
}
export function getBillingLastNameFromOrder(order:any) {
  if (!order || !order.billing || !order.billing.last_name) return '???';
  return order.billing.last_name;
}
export function getBillingMiddleNameFromOrder(order:any) {
  if (!order || !order.billing || !order.billing.middle_name) return '';
  return order.billing.middle_name;
}

export function getBillingIdFromOrder(order:any) {
    if (order && order.billing && order.billing.oyl_id) {
      return order.billing.oyl_id;
    } else if (order && order.oyl_id_billing) {
      return order.oyl_id_billing;
    }
    return 'unknown; error';
}

export function getBillingAddress1FromOrder(order:any) {
  if (order && order.billing && order.billing.address_line) {
    return order.billing.address_line;
  }
  return 'not available';
}

export function getBillingAddress2FromOrder(order:any) {
  if (order && order.billing && order.billing.address_line2) {
    return order.billing.address_line2;
  }
  return '';
}

export function getBillingCityFromOrder(order:any) {
  if (order && order.billing && order.billing.address_city) {
    return order.billing.address_city;
  }
  return 'not available';
}

export function getBillingStateFromOrder(order:any) {
  if (order && order.billing && order.billing.address_state) {
    return order.billing.address_state;
  }
  return 'not available';
}

export function getBillingZipFromOrder(order:any) {
  if (order && order.billing && order.billing.address_zip) {
    return order.billing.address_zip;
  }
  return 'not available';
}

export function getBillingBirthDateFromOrder(order:any) {
  if (order && order.billing && order.billing.birth_date) {
    return dateFromDBStringToPrettySimpleString(order.billing.birth_date, true, true, true);
  }
  return 'not provided';
}

export function getBillingEmailFromOrder(order:any) {
  if (order && order.billing && order.billing.email) {
    return order.billing.email;
  }
  return 'not available';
}

export function getBillingPhoneFromOrder(order:any) {
  if (order && order.billing && order.billing.phone) {
    return order.billing.phone;
  }
  return 'not available';
}

export function getLastEhsResponseFromOrder(order:any) {
  if (order && order.last_response) {
    return JSON.stringify(order.last_response);
  }
  return 'none found';
}