import React, { useState } from 'react';
import SingleOrderView from '../views/SingleOrderComponent';
import './DisplayOrdersComponent.css'; // Optional if you want to add styles
import * as OrderDisplayFormatting from '../tools/OrderDisplayFormatting';

export interface OrderDisplay {
  oyl_id: number;               // in-house order id (NOT the one that the customer sees)
  created: string;              // creation date
  oyl_id_customer: number;      // in-house customer (participant) id number
  oyl_id_billing: number;       // in-house id for non-participant customer who was billed
  oyl_woocommerce_id: number;   // woocommerce order number (THE ONE THAT THE CUSTOMER SEES)
  oyl_skus: string[];           // oyl_skus: list of skus for items purchased
  oyl_coupons: string[];        // oly_coupons: list of coupons
  id: number;                   // EHS ORDER ID Number
  tests: number[];              // Ids for each test, as they appear in the DB's tests table
  ehs_status: string;           // Current status of order according to EHS

  last_name: string;            // last_name from customers table
  first_name: string;           // first_name from customers table
  middle_name: string;          // middle_name from customers table
}

interface DisplayOrdersComponentProps {
  orders: OrderDisplay[];
  session: any;
  noModal?: boolean;
}

const DisplayOrdersComponent: React.FC<DisplayOrdersComponentProps> = ({ orders, session, noModal = false }) => {
  const [selectedOrder, setSelectedOrder] = useState<OrderDisplay | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const disallowModal = noModal;

  // Close the single-order-view modal
  const closeSingleOrderView = () => {
    setSelectedOrder(null);
    setIsModalOpen(false);
  };

  // Open the single-order-view modal, to be populated with data fetched from /api/oyl-order/:orderId
  async function openSingleOrderView(order:any) {
    if (disallowModal) return;
    if (!order || !order.oyl_id || order.oyl_id < 1) {
      // TODO: Create appropriate alert
      console.log("ERROR: Tried to open order but did not find valid order oyl_id!");
      return;
    }
    try {
      const response = await fetch(`/api/oyl-order/${order.oyl_id}`);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      let order_data = await response.json();
      if (!order_data) {
        console.error('Error trying to open SingleOrderView: Failed to get order data');
        return;
      }
      setSelectedOrder(order_data);
      setIsModalOpen(true);
    } catch(error) {
      console.error('Error fetching order:', error);
    }
  }
  
  return (
    <div className="orders-display-container">
      <div className="orders-display-inner-cont">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Customer</th>
              <th>Customer ID</th>
              <th>Separate Billing</th>
              <th>Order Number</th>
              <th>Coupons</th>
              <th>SKUs</th>
              <th>Test IDs</th>
              <th>OYL ID</th>
              <th>EHS Order ID</th>
              <th>EHS Status</th>
            </tr>
          </thead>
          <tbody>
            {orders.length > 0 ? (
              orders.map((order) => (
                <tr key={order.oyl_id} onClick={() => openSingleOrderView(order)}>
                  <td>{OrderDisplayFormatting.getCreatedDateFromOrder(order)}</td>
                  <td>{OrderDisplayFormatting.getCustomerNameFromOrder(order)}</td>
                  <td>{order.oyl_id_customer}</td>
                  <td>{OrderDisplayFormatting.getOYLBillingIDFromOrder(order)}</td>
                  <td>{order.oyl_woocommerce_id}</td>
                  <td>{OrderDisplayFormatting.getOYLCouponsFromOrder(order).join(', ')}</td>
                  <td>{order.oyl_skus.join(', ')}</td>
                  <td>{OrderDisplayFormatting.getOYLTestIDsFromOrder(order).join(', ')}</td>
                  <td>{order.oyl_id}</td>
                  <td>{order.id}</td>
                  <td>{order.ehs_status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No orders found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <SingleOrderView order={selectedOrder} onClose={closeSingleOrderView} />
      )}
      
    </div>
  );
};

export default DisplayOrdersComponent;
