import React, { useState } from 'react';
import SingleBillingView from '../views/SingleBillingComponent';
import './DisplayCustomersComponent.css'; // Optional if you want to add styles
import * as CustomerDisplayFormatting from '../tools/CustomerDisplayFormatting';

export interface BillingDisplay {
  oyl_id: number;               // In-house billing ID
  created: string;              // Billing creation date
  first_name: string;           // Billing's first name
  last_name: string;            // Billing's last name
  middle_name: string;          // Billing's middle name
  email: string;                // Billing's email address
  phone: string;                // Billing's phone number
  gender: string;               // Billing's gender
  address_line: string;         // Billing's address line 1
  address_line2: string;        // Billing's address line 2
  address_city: string;         // Billing's city
  address_state: string;        // Billing's state
  address_zip: string;          // Billing's ZIP code
}

interface DisplayBillingsComponentProps {
  billings: BillingDisplay[];
  session: any;
}

const DisplayBillingsComponent: React.FC<DisplayBillingsComponentProps> = ({ billings, session }) => {
  const [selectedBilling, setSelectedBilling] = useState<BillingDisplay | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Close the single-billing-view modal
  const closeSingleBillingView = () => {
    setSelectedBilling(null);
    setIsModalOpen(false);
  };

  function getNoResultsString() {
    return "No billings found";
  }

  // Open the single-billing-view modal, to be populated with data fetched from /api/oyl-billing/:billingId
  async function openSingleBillingView(billing: any) {
    //console.log(' -- openSingleBillingView with billing: ',billing);
    //console.log(' --- billing.oyl_id: ',billing.oyl_id);
    if (!billing || !billing.oyl_id || billing.oyl_id < 1) {
      console.log("ERROR: Tried to open billing but did not find valid billing oyl_id!");
      return;
    }
    try {
      const query = `/api/oyl-billing/${billing.oyl_id}`;
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      let billing_data = await response.json();
      if (!billing_data) {
        console.error('Error trying to open SingleBillingView: Failed to get billing data');
        return;
      }
      //console.log(' --- about to setSelectedBilling with billing_data: ',billing_data);
      setSelectedBilling(billing_data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching billing:', error);
    }
  }

  return (
    <div className="customers-display-container">
      <div className="customers-display-inner-cont">
        <table>
          <thead>
            <tr>
              <th>Created</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Middle Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Gender</th>
              <th>Address Line 1</th>
              <th>Address Line 2</th>
              <th>City</th>
              <th>State</th>
              <th>ZIP</th>
            </tr>
          </thead>
          <tbody>
            {billings.length > 0 ? (
              billings.map((billing) => (
                <tr key={billing.oyl_id} onClick={() => openSingleBillingView(billing)}>
                  <td>{CustomerDisplayFormatting.getCreatedDateFromCustomer(billing)}</td>
                  <td>{billing.first_name}</td>
                  <td>{billing.last_name}</td>
                  <td>{billing.middle_name}</td>
                  <td>{billing.email}</td>
                  <td>{billing.phone}</td>
                  <td>{billing.gender}</td>
                  <td>{billing.address_line}</td>
                  <td>{billing.address_line2}</td>
                  <td>{billing.address_city}</td>
                  <td>{billing.address_state}</td>
                  <td>{billing.address_zip}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={13}>{getNoResultsString()}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {(isModalOpen) && (
        <SingleBillingView billing={selectedBilling} session={session} onClose={closeSingleBillingView} />
      )}
    </div>
  );
};

export default DisplayBillingsComponent;
