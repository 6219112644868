// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
//import HomePage from './portalcomponent/HomePage';
//import EHSFulfillmentComponent from './portalcomponent/EHSFulfillmentComponent';
import LoginComponent from './logincomponent/LoginComponent';
import DashboardComponent from './portalcomponent/views/DashboardComponent';
import ViewOrdersComponent from './portalcomponent/views/ViewOrdersComponent';
import ViewTestsComponent from './portalcomponent/views/ViewTestsComponent';
import ViewCustomersComponent from './portalcomponent/views/ViewCustomersComponent';
import ViewBillingsComponent from './portalcomponent/views/ViewBillingsComponent';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/fulfillmentportal" element={
          <div className="App">
            <header className="App-header">
              <h1>Portal Login</h1>
              <LoginComponent/>
            </header>
          </div>
        } />

        <Route path="/fulfillmentdashboard" element={
          <div className="DashboardComponent">
            <header className="App-header">
              <DashboardComponent/>
            </header>
          </div>
        } />

        <Route path="/orders" element={
          <div className="ViewOrdersComponent">
            <header className="App-header">
              <h1>View Orders</h1>
              <ViewOrdersComponent/>
            </header>
          </div>
        } />

        <Route path="/tests" element={
          <div className="ViewTestsComponent">
            <header className="App-header">
              <h1>View Tests</h1>
              <ViewTestsComponent/>
            </header>
          </div>
        } />

        <Route path="/customers" element={
          <div className="ViewCustomersComponent">
            <header className="App-header">
              <h1>View Customers</h1>
              <ViewCustomersComponent />
            </header>
          </div>
        } />

        <Route path="/billings" element={
          <div className="ViewBillingsComponent">
            <header className="App-header">
              <h1>View Billings</h1>
              <ViewBillingsComponent />
            </header>
          </div>
        } />

        <Route path="/" element={
          <div className="App redirect">
            <p>This .net page is not active. Please visit:</p>
            <a href="https://ownyourlabs.com">OwnYourLabs.com</a>
          </div>
        } />
      </Routes>
    </Router>
  );
  
}

export default App;
