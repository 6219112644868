import React from 'react';
import './SessionReadoutComponent.css';

const SessionReadout: React.FC<any> = ({ session, viewname }) => {

  const getRole = ():string => {
    if (!session) {
      return 'undefined';
    }
    if (!session.role) {
      return 'unknown';
    }
    if (session.role < 2) {
      return 'Dev Admin';
    }
    return 'Fulfillment Team Admin';
  };

  return (
    <div className="session-readout-cont">
      <div className="session-readout-inner-cont">
        <div className="user-row">
          <div className="role">Role: {getRole()}</div>
          <div className="username">User: {session.username}</div>
        </div>
        <div className="viewname">{viewname}</div>
      </div>
    </div>
  );
};

export default SessionReadout;