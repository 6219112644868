import React, { useState } from 'react';
import SingleTestView from '../views/SingleTestComponent';
import './DisplayTestsComponent.css'; // Optional if you want to add styles
import * as TestDisplayFormatting from '../tools/TestDisplayFormatting';

export interface TestDisplay {
  oyl_id: number;             // Test ID
  created: string;            // Creation date
  oyl_id_order: number;       // Associated order ID
  oyl_woocommerce_id: number; // Woocommerce ID
  sku: string;                // SKU
}

interface DisplayTestsComponentProps {
  tests: TestDisplay[];
}

const DisplayTestsComponent: React.FC<DisplayTestsComponentProps> = ({ tests }) => {
  const [selectedTest, setSelectedTest] = useState<TestDisplay | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Close the single-test-view modal
  const closeSingleTestView = () => {
    setSelectedTest(null);
    setIsModalOpen(false);
  };

  // Open the single-test-view modal, to be populated with data fetched from /api/oyl-test/:testId
  async function openSingleTestView(test: any) {
    //console.log(' --- openSingleTestView --- test: ',test);
    if (!test || !test.oyl_id) {
      console.log("ERROR: Tried to open test but did not find valid test ID!");
      return;
    }
    try {
      const response = await fetch(`/api/oyl-test/${test.oyl_id}`);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const test_data = await response.json();
      if (!test_data) {
        console.error('Error trying to open SingleTestView: Failed to get test data');
        return;
      }
      setSelectedTest(test_data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching test details:', error);
    }
  }

  return (
    <div className="tests-display-container">
      <div className="tests-display-inner-cont">
        <table>
          <thead>
            <tr>
              <th>OYL ID</th>
              <th>Date Created</th>
              <th>Order ID</th>
              <th>Woocommerce ID</th>
              <th>SKU</th>
            </tr>
          </thead>
          <tbody>
            {tests.length > 0 ? (
              tests.map((test) => (
                <tr key={test.oyl_id} onClick={() => openSingleTestView(test)}>
                  <td>{test.oyl_id}</td>
                  <td>{TestDisplayFormatting.getFormattedDate(test.created)}</td>
                  <td>{test.oyl_id_order}</td>
                  <td>{test.oyl_woocommerce_id}</td>
                  <td>{test.sku}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No tests found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <SingleTestView test={selectedTest} onClose={closeSingleTestView} />
      )}
    </div>
  );
};

export default DisplayTestsComponent;
