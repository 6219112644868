
import { dateFromDBStringToPrettySimpleString } from './DateFunctions';

// Get a viewer-friendly formatting of the created date of the given order
export function getCreatedDateFromCustomer(customer:any) {
  if (customer && customer.created) {
    return dateFromDBStringToPrettySimpleString(customer.created, true);
  }
  return '???';
}

export function getFormattedBirthDate(customer:any) {
  if (customer && customer.birth_date) {
    return dateFromDBStringToPrettySimpleString(customer.birth_date, true, false, true);
  }
  return '???';
}
