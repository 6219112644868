import React, { useState } from 'react';
import './SingleBillingComponent.css';
import * as CDF from '../tools/CustomerDisplayFormatting';
import DisplayOrdersComponent from '../widgets/DisplayOrdersComponent';

const SingleBillingView: React.FC<any> = ({ billing, session, onClose }) => {
  if (!billing) return null;
  //console.log(' -- billing: ', billing);

  // Commit button modes
  const CLEAN_MODE = 'clean';
  const EDIT_MODE = 'editing';
  const AWAIT_MODE = 'awaiting';

  // State for editable fields
  const [firstName, setFName] = useState(billing.first_name);
  const [middleName, setMName] = useState(billing.middle_name);
  const [lastName, setLName] = useState(billing.last_name);
  const [email, setEm] = useState(billing.email);
  const [phone, setPh] = useState(billing.phone);
  const [address1, setAdd1] = useState(billing.address_line);
  const [address2, setAdd2] = useState(billing.address_line2);
  const [city, setCt] = useState(billing.address_city);
  const [state, setSt] = useState(billing.address_state);
  const [zip, setZ] = useState(billing.address_zip);

  // State for server response and modes
  const [resp, setResp] = useState("");
  const [mode, setMode] = useState(CLEAN_MODE);
  
  const setFirstName = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setFName(val);
  };
  const setMiddleName = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setMName(val);
  };
  const setLastName = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setLName(val);
  };
  const setEmail = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setEm(val);
  };
  const setPhone = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setPh(val);
  };
  const setAddress1 = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setAdd1(val);
  };
  const setAddress2 = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setAdd2(val);
  };
  const setCity = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setCt(val);
  };
  const setState = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setSt(val);
  };
  const setZip = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setZ(val);
  };

  // Commit changes to the server
  const handleCommit = async () => {
    setMode(AWAIT_MODE);
    try {
      const response = await fetch('/api/update-billing-by-id', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          oyl_id: billing.oyl_id,
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          email: email,
          phone: phone,
          address_line: address1,
          address_line2: address2,
          address_city: city,
          address_state: state,
          address_zip: zip,
          session_user_id: session.userId,
          edit_note: 'none'//yoda TODO: implement ability to add note
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      console.log('Billing updated successfully:', result);
      setResp(result.message);
      
      // Optionally, handle post-update actions like closing the modal or showing a success message
    } catch (error) {
      setResp('Error: Failed to update billing');
      console.error(resp + ':', error);
    } finally {
      setMode(CLEAN_MODE);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content-container">
        <div className="modal-content">
          <div className="single-billing-cont">
            <div className="single-billing-inner-cont">
              <div className="billing-header-info left-half">Billing Since: { CDF.getCreatedDateFromCustomer(billing) }</div>
              <div className="billing-header-info right-half">Billing OYL ID: { billing.oyl_id }</div>
              <div className="billing-header-info">Billing Name: { billing.first_name } { billing.middle_name } { billing.last_name }</div>
              <div className="scrolling-info-container">
                <div className="info-section billing-info">
                  <div className="info-section-header">Billing Info:</div>
                  <div className="subsection billing-name">
                    <div className="name-field inline-bullet first-name">
                      First Name: <input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                    <div className="name-field inline-bullet middle-name">
                      Middle Name: <input value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
                    </div>
                    <div className="name-field inline-bullet last-name">
                      Last Name: <input value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                  </div>

                  <div className="subsection email">Email: <input value={email} onChange={(e) => setEmail(e.target.value)} /></div>
                  <div className="subsection phone">Phone Number: <input value={phone} onChange={(e) => setPhone(e.target.value)} /></div>
                  <div className="subsection address">
                    <div>Address Line 1: <input value={address1} onChange={(e) => setAddress1(e.target.value)} /></div>
                    <div>Address Line 2: <input value={address2} onChange={(e) => setAddress2(e.target.value)} /></div>
                    <div>City: <input value={city} onChange={(e) => setCity(e.target.value)} /></div>
                    <div>State: <input value={state} onChange={(e) => setState(e.target.value)} /></div>
                    <div>ZIP: <input value={zip} onChange={(e) => setZip(e.target.value)} /></div>
                  </div>
                  
                  {billing.orders &&  (
                      <div className="subsection">
                        <div>Orders:</div>
                        <DisplayOrdersComponent orders={billing.orders} session={session} noModal={true}></DisplayOrdersComponent>
                      </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          
          <div className={`commit-button ${mode}`} onClick={handleCommit}>
            Commit Changes
          </div>
          <div className={`waiting-message ${mode}`}>Waiting...</div>
          <div className="commit-response">{resp}</div>
          
          <div onClick={onClose} className="close-modal">X</div>
        </div>
      </div>
    </div>
  );
};

export default SingleBillingView;