import React, { useState, useEffect } from 'react';

interface ModePickerProps {
  modes: string[];
  onModeChange: (mode: string) => void;
}
var isFirstRender = true;

const ModePicker: React.FC<ModePickerProps> = ({ modes, onModeChange }) => {
  const [selectedMode, setSelectedMode] = useState<string>(modes[0]);

  useEffect(() => {
    if (isFirstRender) {
      setSelectedMode(modes[0]);
      onModeChange(modes[0]);
      isFirstRender = false;
    }
  }, [modes]);

  const handleModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newMode = event.target.value;
    setSelectedMode(newMode);
    onModeChange(newMode);
  };

  return (
    <div className="picker-cont mode-picker-cont">
      <select className="picker-dropdown mode-picker" value={selectedMode} onChange={handleModeChange}>
        {modes.map((mode) => (
          <option className="picker-option mode-picker-option" key={mode} value={mode}>
            {mode}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ModePicker;
