import React from 'react';
import './SingleTestComponent.css';
import * as TestDisplayFormatting from '../tools/TestDisplayFormatting';

const SingleTestView: React.FC<any> = ({ test, onClose }) => {
  if (!test) return null;
  console.log(' -- test: ', test); // Optional: Comment out if not needed

  return (
    <div className="modal-overlay">
      <div className="modal-content-container">
        <div className="modal-content">
          <div className="single-test-cont">
            <div className="single-test-inner-cont">
              <div className="test-header-info left-half">Test ID: {test.oyl_id}</div>
              <div className="test-header-info right-half">Created On: {TestDisplayFormatting.getFormattedDate(test.created)}</div>
              <div className="scrolling-info-container">
                <div className="info-section test-info">
                  <div className="info-section-header">Test Details:</div>
                  <div className="subsection">
                    <div className="inline-bullet">Test ID: {test.oyl_id}</div>
                  </div>
                  <div className="subsection">
                    <div className="inline-bullet">Order ID: {test.oyl_id_order}</div>
                  </div>
                  <div className="subsection">
                    <div className="inline-bullet">Woocommerce ID: {test.oyl_woocommerce_id}</div>
                  </div>
                  <div className="subsection">
                    <div className="inline-bullet">SKU: {test.sku}</div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div onClick={onClose} className="close-modal">X</div>
        </div>
      </div>
    </div>
  );
};

export default SingleTestView;
