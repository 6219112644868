import React from 'react';
import './LeftNavComponent.css';
import { useNavigate } from 'react-router-dom';

export interface LeftNavProps {
  currentview: string;
};

const LeftNav: React.FC<LeftNavProps> = ({currentview }) => {

  const navigate = useNavigate();

  function goToDashboard(clickEvent:any) {
    if (currentview === 'dashboard') return;
    navigate('/fulfillmentdashboard');
  }

  function goToOrders(clickEvent:any) {
    if (currentview === 'orders') return;
    navigate('/orders');
  }

  function goToTests(clickEvent:any) {
    if (currentview === 'tests') return;
    navigate('/tests');
  }

  function goToCustomers(clickEvent:any) {
    if (currentview === 'customers') return;
    navigate('/customers');
  }

  function goToBillings(clickEvent:any) {
    if (currentview === 'billings') return;
    navigate('/billings');
  }

  function handleLogout(clickEvent:any) {
    navigate('/fulfillmentportal'); 
  }
  
  return (
    <div className='left-nav-cont'>
      <div className='left-nav-inner-cont'>
        <div className={`option ${currentview === 'dashboard' ? 'current-view' : ''}`} onClick={goToDashboard}>Dashboard</div>
        <div className={`option ${currentview === 'orders' ? 'current-view' : ''}`} onClick={goToOrders}>Orders</div>
        <div className={`option ${currentview === 'tests' ? 'current-view' : ''}`} onClick={goToTests}>Tests</div>
        <div className={`option ${currentview === 'customers' ? 'current-view' : ''}`} onClick={goToCustomers}>Customers</div>
        <div className={`option ${currentview === 'billings' ? 'current-view' : ''}`} onClick={goToBillings}>Billings</div>
        <div className='gap'></div>
        <div className='option' onClick={handleLogout}>LOGOUT</div>
      </div>
    </div>
  );
};

export default LeftNav;