import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardComponent.css';
// import DatePicker from '../widgets/DatePicker';
// import ModePicker from '../widgets/ModePicker';
import DisplayOrdersComponent from '../widgets/DisplayOrdersComponent';
import { OrderDisplay } from '../widgets/DisplayOrdersComponent';
import SessionReadout from '../widgets/SessionReadoutComponent';
import LeftNav from '../widgets/LeftNavComponent';

const DashboardComponent: React.FC = () => {
  const [session, setSession] = useState<{ username: string; role: string }>({ username: '', role: '' });
  const [message, setMessage] = useState('');
  const [orderID, setOrderID] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const ORDERS_BY_DATE = 'Find Orders by Date';
  const ORDER_BY_ID = 'View Order By ID';
  const CREATE_ORDER = 'Create Order';
  var selected_date:string = '199901010000';
  
  const [displayedOrders, setDisplayedOrders] = useState<OrderDisplay[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch('/api/session');
        const sessionData = await response.json();

        if (sessionData.loggedIn) { // Replace 'admin' with the required role
          setIsAuthorized(true);
          setSession(sessionData);
          // console.log('session.username: ', session.username);
          // console.log('session.role: ', session.role);
          fetchRecentOrders();
        } else {
          navigate('/fulfillmentportal'); // Redirect to login if not authorized
        }
      } catch (error) {
        console.error('Error checking session:', error);
        navigate('/fulfillmentportal'); // Redirect to login if there's an error
      }
    };

    checkSession();
  }, [navigate]);

  const fetchRecentOrders = async () => {
    try {
      let response = await fetch(`/api/all-recent-orders`);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      let data = await response.json();
      // console.log('Response for request of all orders:');
      // console.log(data);
      setDisplayedOrders(data);
    } catch (error) {
      setMessage('Error fetching orders');
      console.error('Error fetching order details:', error);
      throw error;
    }
  };

  //const modes = [ORDERS_BY_DATE, ORDER_BY_ID, CREATE_ORDER];
  // const modes = [ORDERS_BY_DATE, ORDER_BY_ID];
  // const [currentMode, setCurrentMode] = useState<string>(modes[0]);

  // const handleModeChange = (mode: string) => {
  //   setCurrentMode(mode);
  // };

  // const handleDateChange = (date: string) => {
  //   selected_date = date;
  // }

  // const handleSubmit = async (e: React.FormEvent) => {
  //   setMessage('');
  //   e.preventDefault();

  //   switch(currentMode) {
  //     case ORDERS_BY_DATE:
  //       try {
  //         let response = await fetch(`/api/orders?fromDate=${selected_date}`);
  //         if (!response.ok) {
  //           throw new Error(`Error: ${response.statusText}`);
  //         }
  //         let data = await response.json();
  //         console.log('Response for request of all orders:');
  //         console.log(data);
  //         setMessage('');
  //         //return data;
  //       } catch (error) {
  //         setMessage('Error fetching orders');
  //         console.error('Error fetching order details:', error);
  //         throw error;
  //       }
  //       break;
  //     case ORDER_BY_ID:
  //       if (orderID && orderID.length > 0) {
  //         try {
  //           let response = await fetch(`/api/order/${orderID}`);
  //           if (!response.ok) {
  //             throw new Error(`Error: ${response.statusText}`);
  //           }
  //           let data = await response.json();
  //           console.log('Response for info request regarding order ' + orderID + ':');
  //           console.log(data);
  //           return data;
  //         } catch (error) {
  //           setMessage('Error fetching order details for ' + orderID);
  //           console.error('Error fetching order details:', error);
  //           throw error;
  //         }
  //       } else {
  //         setMessage('You have to enter an ID');
  //       }
  //       break;

  //     case CREATE_ORDER:
  //       try {
  //         let test_body = {
  //           id: '1275721',
  //           billing_first_name: 'Optimistic',
  //           billing_last_name: 'O_YEAH',
  //           billing_phone: '8085553555',
  //           billing_email: 'soexcited@pointersisters.com',
  //           billing_address_1: '123 Givitoomee Babe',
  //           billing_address_2: '',
  //           billing_city: 'Weirdosburgh',
  //           billing_state: 'PA',
  //           billing_postcode: '15224',
  //           skus: [ '602526', '322000' ],
  //           labreqdob_: '21.06.02',
  //           labreqsex_: 'male'
  //         };
  //         let response = await fetch('/api/create_order', {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json'
  //           },
  //           body: JSON.stringify(test_body)
  //         });
  //         if (!response.ok) {
  //           throw new Error(`Error: ${response.statusText}`);
  //         }
  //         let data = await response.json();
  //         console.log('Response for creating new order:');
  //         console.log(data);
  //         setMessage('Pretended to create an order!');
  //         //return data;
  //       } catch (error) {
  //         setMessage('Error creating order');
  //         console.error('Error creating order:', error);
  //         throw error;
  //       }
  //       break;

  //     default:
  //       setMessage('WEIRDNESS!! -- invalid currentMode: '+currentMode);
  //   }
  // };

  // const goToViewOrders = async (e: React.FormEvent) => {
  //   navigate('/vieworders');
  // }

  if (!isAuthorized) {
    return <div>Loading...</div>; // Show a loading state while checking authorization
  }

  return (
    <div className="fulfillment-dashboard-container">
      <LeftNav currentview="dashboard"/>
      <div className="inner-cont">
        <SessionReadout className="page-headers" session={session} viewname="dashboard"/>
        <div className="recent-orders-label">Recent Orders</div>
        <DisplayOrdersComponent orders={displayedOrders} session={session}/>
      </div>
    </div>
  );

  // return (
  //   <div className="fulfillment-dashboard-container">
  //     <div className="inner-cont">
  //       <button type="submit" onClick={goToViewOrders}>View Orders</button>
  //       <ModePicker modes={modes} onModeChange={handleModeChange} />
  //       <h5>{currentMode}</h5>
  //       { currentMode == ORDERS_BY_DATE ? (
  //         <form>
  //           <DatePicker onDateChange={handleDateChange} earliestYear={2010}></DatePicker>
  //         </form> ) : null
  //       }
  //       {currentMode == ORDER_BY_ID ? (<form onSubmit={handleSubmit}>
  //         <div className="form-group">
  //           <label htmlFor="orderID">Order ID</label>
  //           <input
  //             className="old-db-input"
  //             type="text"
  //             id="orderID"
  //             value={orderID}
  //             onChange={(e) => setOrderID(e.target.value)}
  //           />
  //         </div>
  //       </form>) : null}
  //       {currentMode == CREATE_ORDER ? (<form onSubmit={handleSubmit}>
  //         <div className="form-group">
  //           <label htmlFor="orderID">Order ID</label>
  //           <input
  //             className="old-db-input"
  //             type="text"
  //             id="orderID"
  //             value={orderID}
  //             onChange={(e) => setOrderID(e.target.value)}
  //           />
  //         </div>
  //       </form>) : null}
  //       <button type="submit" onClick={handleSubmit}>Send</button>
  //     </div>
  //     {message && <p>{message}</p>}
  //   </div>
  // );
};

export default DashboardComponent;


