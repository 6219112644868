import React, { useState } from 'react';
import SingleCustomerView from '../views/SingleCustomerComponent';
import './DisplayCustomersComponent.css'; // Optional if you want to add styles
import * as CustomerDisplayFormatting from '../tools/CustomerDisplayFormatting';

export interface CustomerDisplay {
  oyl_id: number;               // In-house customer ID
  created: string;              // Customer creation date
  first_name: string;           // Customer's first name
  last_name: string;            // Customer's last name
  middle_name: string;          // Customer's middle name
  birth_date: string;           // Customer's birth date
  email: string;                // Customer's email address
  phone: string;                // Customer's phone number
  gender: string;               // Customer's gender
  address_line: string;         // Customer's address line 1
  address_line2: string;        // Customer's address line 2
  address_city: string;         // Customer's city
  address_state: string;        // Customer's state
  address_zip: string;          // Customer's ZIP code
}

interface DisplayCustomersComponentProps {
  customers: CustomerDisplay[];
  session: any;
}

const DisplayCustomersComponent: React.FC<DisplayCustomersComponentProps> = ({ customers, session }) => {
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerDisplay | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Close the single-customer-view modal
  const closeSingleCustomerView = () => {
    setSelectedCustomer(null);
    setIsModalOpen(false);
  };

  function getNoResultsString() {
    return "No customers found";
  }

  // Open the single-customer-view modal, to be populated with data fetched from /api/oyl-customer/:customerId
  async function openSingleCustomerView(customer: any) {
    //console.log(' -- openSingleCustomerView with customer: ',customer);
    //console.log(' --- customer.oyl_id: ',customer.oyl_id);
    if (!customer || !customer.oyl_id || customer.oyl_id < 1) {
      console.log("ERROR: Tried to open customer but did not find valid customer oyl_id!");
      return;
    }
    try {
      const sql = `/api/oyl-customer/${customer.oyl_id}`;
      const response = await fetch(sql);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      let customer_data = await response.json();
      if (!customer_data) {
        console.error('Error trying to open SingleCustomerView: Failed to get customer data');
        return;
      }
      //console.log(' --- about to setSelectedCustomer with customer_data: ',customer_data);
      setSelectedCustomer(customer_data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching customer:', error);
    }
  }

  return (
    <div className="customers-display-container">
      <div className="customers-display-inner-cont">
        <table>
          <thead>
            <tr>
              <th>Created</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Middle Name</th>
              <th>Birth Date</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Gender</th>
              <th>Address Line 1</th>
              <th>Address Line 2</th>
              <th>City</th>
              <th>State</th>
              <th>ZIP</th>
            </tr>
          </thead>
          <tbody>
            {customers.length > 0 ? (
              customers.map((customer) => (
                <tr key={customer.oyl_id} onClick={() => openSingleCustomerView(customer)}>
                  <td>{CustomerDisplayFormatting.getCreatedDateFromCustomer(customer)}</td>
                  <td>{customer.first_name}</td>
                  <td>{customer.last_name}</td>
                  <td>{customer.middle_name}</td>
                  <td>{CustomerDisplayFormatting.getFormattedBirthDate(customer)}</td>
                  <td>{customer.email}</td>
                  <td>{customer.phone}</td>
                  <td>{customer.gender}</td>
                  <td>{customer.address_line}</td>
                  <td>{customer.address_line2}</td>
                  <td>{customer.address_city}</td>
                  <td>{customer.address_state}</td>
                  <td>{customer.address_zip}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={13}>{getNoResultsString()}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {(isModalOpen) && (
        <SingleCustomerView customer={selectedCustomer} session={session} onClose={closeSingleCustomerView} />
      )}
    </div>
  );
};

export default DisplayCustomersComponent;
