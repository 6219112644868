import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ViewTestsComponent.css';
import DisplayTestsComponent from '../widgets/DisplayTestsComponent';
import SessionReadout from '../widgets/SessionReadoutComponent';
import LeftNav from '../widgets/LeftNavComponent';
import ModePicker from '../widgets/ModePicker';
import DatePicker from '../widgets/DatePicker';
import Checkbox from '../widgets/CheckboxComponent';
import SearchField from '../widgets/SearchFieldComponent';
import { dateFromEhsStringToMs } from '../tools/DateFunctions';

const ViewTestsComponent: React.FC = () => {
  const [session, setSession] = useState<{ username: string; role: string }>({ username: '', role: '' });
  const [isAuthorized, setIsAuthorized] = useState(false);
  
  const [displayedTests, setDisplayedTests] = useState<any[]>([]);

  const NOT_USING = 'Not Using';
  const ON_DATE = 'On Date';
  const NEAR_DATE = 'Near Date';
  const AFTER_DATE = 'After Date';
  const DATE_RANGE = 'Between Dates';
  
  const dateTypeModes = [NOT_USING, ON_DATE, NEAR_DATE, AFTER_DATE, DATE_RANGE];
  const [currentDateTypeMode, setCurrentDateTypeMode] = useState<string>(dateTypeModes[0]);
  const [selectedDate1, setSelectedDate1] = useState<number>(new Date().getTime());
  const [selectedDate2, setSelectedDate2] = useState<number>(new Date().getTime());

  const [checkingId, setCheckingId] = useState<boolean>(false);
  const [checkingOrderId, setCheckingOrderId] = useState<boolean>(false);
  const [checkingWooID, setCheckingWooID] = useState<boolean>(false);
  const [checkingSku, setCheckingSku] = useState<boolean>(false);

  const [idSearchString, setIdSearchString] = useState<string>('');
  const [orderIdSearchString, setOrderIdSearchString] = useState<string>('');
  const [wooIDSearchString, setWooIDSearchString] = useState<string>('');
  const [skuSearchString, setSkuSearchString] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch('/api/session');
        const sessionData = await response.json();

        if (sessionData.loggedIn) {
          setIsAuthorized(true);
          setSession(sessionData);
        } else {
          navigate('/fulfillmentportal'); // Redirect to login if not authorized
        }
      } catch (error) {
        console.error('Error checking session:', error);
        navigate('/fulfillmentportal'); // Redirect to login if there's an error
      }
    };

    checkSession();
  }, [navigate]);

  const handleDateTypeModeChange = (mode: string) => {
    setCurrentDateTypeMode(mode);
  };

  const handleDate1Change = (date: string) => {
    setSelectedDate1(dateFromEhsStringToMs(date));
  };

  const handleDate2Change = (date: string) => {
    setSelectedDate2(dateFromEhsStringToMs(date));
  };

  const handleIdCheck = (isChecked: boolean) => {
    setCheckingId(isChecked);
  };

  const handleOrderIdCheck = (isChecked: boolean) => {
    setCheckingOrderId(isChecked);
  };

  const handleWooIDCheck = (isChecked: boolean) => {
    setCheckingWooID(isChecked);
  };

  const handleSkuCheck = (isChecked: boolean) => {
    setCheckingSku(isChecked);
  };

  const handleIdSearchText = (searchString: string) => {
    setIdSearchString(searchString);
  };

  const handleOrderIdSearchText = (searchString: string) => {
    setOrderIdSearchString(searchString);
  };

  const handleWooIDSearchText = (searchString: string) => {
    setWooIDSearchString(searchString);
  };

  const handleSkuSearchText = (searchString: string) => {
    setSkuSearchString(searchString);
  };

  const searchTests = async () => {
    let url = `/api/oyl-tests-filtered`;
    let first_param_added = false;
    const ms4days = 345600000;
    const ms10days = 864000000;
    let calcDate1, calcDate2;
    //console.log(' --- currentDateTypeMode: ',currentDateTypeMode);
    switch(currentDateTypeMode) {
      case ON_DATE:
        // only uses selectedDate1
        url += `?date1=${selectedDate1}`;
        first_param_added = true;
        break;
      case NEAR_DATE:
        // only uses selectedDate1
        calcDate1 = selectedDate1 - ms4days;
        calcDate2 = selectedDate1 + ms4days;
        url += `?date1=${calcDate1}&date2=${calcDate2}`;
        first_param_added = true;
        break;
      case AFTER_DATE:
        // only uses date1
        calcDate1 = selectedDate1;
        calcDate2 = selectedDate1 + ms10days;
        url += `?date1=${calcDate1}&date2=${calcDate2}`;
        first_param_added = true;
        break;
      case DATE_RANGE:
        // uses both date1 and date2
        if (selectedDate2 < selectedDate1) {
          calcDate1 = selectedDate2;
          calcDate2 = selectedDate1;
        } else {
          calcDate1 = selectedDate1;
          calcDate2 = selectedDate2;
        }
        url += `?date1=${calcDate1}&date2=${calcDate2}`;
        first_param_added = true;
        break;

      case NOT_USING:
        break;
    }

    if (checkingId && idSearchString) {
      url += `?id=${idSearchString}`;
      first_param_added = true;
    }
    if (checkingOrderId && orderIdSearchString) {
      url += `${first_param_added ? '&' : '?'}order_id=${orderIdSearchString}`;
      first_param_added = true;
    }
    if (checkingWooID && wooIDSearchString) {
      url += `${first_param_added ? '&' : '?'}woocommerce_id=${wooIDSearchString}`;
      first_param_added = true;
    }
    if (checkingSku && skuSearchString) {
      url += `${first_param_added ? '&' : '?'}sku=${skuSearchString}`;
      first_param_added = true;
    }

    try {
      let response = await fetch(url);
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      let data = await response.json();
      setDisplayedTests(data);
    } catch (error) {
      console.error('Error fetching test details:', error);
      throw error;
    }
  };

  return (
    <div className="fulfillment-dashboard-container">
      <LeftNav currentview="tests" />
      <div className="search-tests-cont">
        <div className="inner-cont">
          <SessionReadout className="page-headers" session={session} viewname="tests - search" />
          <div className="search-results-label">Search Results:</div>
          <div className="search-settings">
            <div className="search-settings-inner">
              <div className="widgets-row">
                <div className="settings-widget">
                  <div className="search-tests-sw-label date-created">Date Created</div>
                  <ModePicker modes={dateTypeModes} onModeChange={handleDateTypeModeChange}></ModePicker>
                  {currentDateTypeMode !== NOT_USING ? (
                    <form>
                      <DatePicker onDateChange={handleDate1Change} earliestYear={2020}></DatePicker>
                    </form>
                  ) : null}
                  {currentDateTypeMode === DATE_RANGE ? (
                    <form>
                      <DatePicker onDateChange={handleDate2Change} earliestYear={2020}></DatePicker>
                    </form>
                  ) : null}
                </div>
              </div>

              <div className="widgets-row">
                <div className="settings-widget">
                  <div className="search-tests-sw-label">SKU</div>
                  <SearchField onTextChange={handleSkuSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleSkuCheck}></Checkbox>
                </div>

                <div className="settings-widget">
                  <div className="search-tests-sw-label">Woocommerce ID</div>
                  <SearchField onTextChange={handleWooIDSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleWooIDCheck}></Checkbox>
                </div>

                <div className="settings-widget">
                  <div className="search-tests-sw-label">OYL Test ID</div>
                  <SearchField onTextChange={handleIdSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleIdCheck}></Checkbox>
                </div>

                <div className="settings-widget">
                  <div className="search-tests-sw-label">Order ID</div>
                  <SearchField onTextChange={handleOrderIdSearchText} maxChars={16}></SearchField>
                  <Checkbox label="Using" initialChecked={false} onCheckedChange={handleOrderIdCheck}></Checkbox>
                </div>
              </div>

            </div>
          </div>
          <div className="search-button" onClick={searchTests}>SEARCH</div>
          <DisplayTestsComponent tests={displayedTests} />
        </div>
      </div>
    </div>
  );
};

export default ViewTestsComponent;
