import React, { useState, useEffect } from 'react';

interface CheckboxProps {
  label: string;
  initialChecked?: boolean;
  onCheckedChange: (checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  initialChecked = false,
  onCheckedChange,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(initialChecked);

  useEffect(() => {
    onCheckedChange(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div className='checkbox-wrapper'>
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
