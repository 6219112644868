import React, { useState } from 'react';
import './SingleCustomerComponent.css';
import * as CDF from '../tools/CustomerDisplayFormatting';
import DisplayOrdersComponent from '../widgets/DisplayOrdersComponent';
import DatePicker from '../widgets/DatePicker';
import { fromCustomerDateToDBDate, fromDateDateToDatePickerFormat } from '../tools/DateFunctions';

const SingleCustomerView: React.FC<any> = ({ customer, session, onClose }) => {
  if (!customer) return null;
  //console.log(' -- customer: ', customer);

  // Commit button modes
  const CLEAN_MODE = 'clean';
  const EDIT_MODE = 'editing';
  const AWAIT_MODE = 'awaiting';
  const initialBD = fromDateDateToDatePickerFormat(customer.birth_date);

  // State for editable fields
  const [firstName, setFName] = useState(customer.first_name);
  const [middleName, setMName] = useState(customer.middle_name);
  const [lastName, setLName] = useState(customer.last_name);
  const [birthDate, setBD] = useState(initialBD);
  const [email, setEm] = useState(customer.email);
  const [phone, setPh] = useState(customer.phone);
  const [gender, setGen] = useState(customer.gender);
  const [address1, setAdd1] = useState(customer.address_line);
  const [address2, setAdd2] = useState(customer.address_line2);
  const [city, setCt] = useState(customer.address_city);
  const [state, setSt] = useState(customer.address_state);
  const [zip, setZ] = useState(customer.address_zip);

  // State for server response and modes
  const [resp, setResp] = useState("");
  const [mode, setMode] = useState(CLEAN_MODE);

  const setFirstName = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setFName(val);
  };
  const setMiddleName = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setMName(val);
  };
  const setLastName = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setLName(val);
  };
  const setEmail = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setEm(val);
  };
  const setPhone = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setPh(val);
  };
  const setGender = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setGen(val);
  };
  const setAddress1 = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setAdd1(val);
  };
  const setAddress2 = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setAdd2(val);
  };
  const setCity = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setCt(val);
  };
  const setState = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setSt(val);
  };
  const setZip = (val: string) => {
    if (mode === CLEAN_MODE) setMode(EDIT_MODE);
    setZ(val);
  };

  const setBirthDate = (val: any) => {
    if (mode === CLEAN_MODE && val !== initialBD) setMode(EDIT_MODE);
    setBD(val);
  };

  // Commit changes to the server
  const handleCommit = async () => {
    setMode(AWAIT_MODE);
    try {
      const response = await fetch('/api/update-customer-by-id', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          oyl_id: customer.oyl_id,
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          birth_date: fromCustomerDateToDBDate(birthDate),
          email: email,
          phone: phone,
          gender: gender,
          address_line: address1,
          address_line2: address2,
          address_city: city,
          address_state: state,
          address_zip: zip,
          session_user_id: session.userId,
          edit_note: 'none'//yoda TODO: implement ability to add note
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      console.log('Customer updated successfully:', result);
      setResp(result.message);

      // Optionally, handle post-update actions like closing the modal or showing a success message
    } catch (error) {
      setResp('Error: Failed to update billing');
      console.error(resp + ':', error);
    } finally {
      setMode(CLEAN_MODE);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content-container">
        <div className="modal-content">
          <div className="single-customer-cont">
            <div className="single-customer-inner-cont">
              <div className="customer-header-info left-half">Customer Since: { CDF.getCreatedDateFromCustomer(customer) }</div>
              <div className="customer-header-info right-half">Customer OYL ID: { customer.oyl_id }</div>
              <div className="customer-header-info">Customer Name: { customer.first_name } { customer.middle_name } { customer.last_name }</div>
              <div className="scrolling-info-container">
                <div className="info-section customer-info">
                  <div className="info-section-header">Customer Info:</div>
                  <div className="subsection customer-name">
                    <div className="name-field inline-bullet first-name">
                      First Name: <input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                    <div className="name-field inline-bullet middle-name">
                      Middle Name: <input value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
                    </div>
                    <div className="name-field inline-bullet last-name">
                      Last Name: <input value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                  </div>

                  {/* Replace birthDate input with DatePicker */}
                  <div className="subsection birthdate">
                    Date of Birth: 
                    <DatePicker 
                      onDateChange={(date) => setBirthDate(date)} 
                      initialYear={new Date(customer.birth_date).getFullYear()}
                      initialMonth={new Date(customer.birth_date).getMonth() + 1}
                      initialDay={new Date(customer.birth_date).getDate()}
                      earliestYear={1900}
                    />
                  </div>

                  <div className="subsection email">Email: <input value={email} onChange={(e) => setEmail(e.target.value)} /></div>
                  <div className="subsection phone">Phone Number: <input value={phone} onChange={(e) => setPhone(e.target.value)} /></div>
                  <div className="subsection gender">Gender: <input value={gender} onChange={(e) => setGender(e.target.value)} /></div>
                  <div className="subsection address">
                    <div>Address Line 1: <input value={address1} onChange={(e) => setAddress1(e.target.value)} /></div>
                    <div>Address Line 2: <input value={address2} onChange={(e) => setAddress2(e.target.value)} /></div>
                    <div>City: <input value={city} onChange={(e) => setCity(e.target.value)} /></div>
                    <div>State: <input value={state} onChange={(e) => setState(e.target.value)} /></div>
                    <div>ZIP: <input value={zip} onChange={(e) => setZip(e.target.value)} /></div>
                  </div>
                  
                  {customer.orders &&  (
                      <div className="subsection">
                        <div>Orders:</div>
                        <DisplayOrdersComponent orders={customer.orders} session={session} noModal={true}></DisplayOrdersComponent>
                      </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          
          <div className={`commit-button ${mode}`} onClick={handleCommit}>
            Commit Changes
          </div>
          <div className={`waiting-message ${mode}`}>Waiting...</div>
          <div className="commit-response">{resp}</div>
          
          <div onClick={onClose} className="close-modal">X</div>
        </div>
      </div>
    </div>
  );
};

export default SingleCustomerView;
